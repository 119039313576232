import React from 'react';
import Img from 'gatsby-image';
import css from './index.module.css';

const Image = ({ image }) => {
  return (
    <div className={css.image}>
      <Img className={css.image__element} fluid={image.fluid} />
    </div>
  );
};

export default Image;
