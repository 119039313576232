import React from 'react';
import Navigation from '../Navigation';
import css from './index.module.css';
import { Link } from 'gatsby';

export default () => (
  <header className={css.header}>
    <div className={css.header__wrapper}>
      <div className={css.header__logo}>
        <Link className={css.header__link} to="/">
          Sulzberg
        </Link>
      </div>
      <div className={css.header__navigation}>
        <Navigation />
      </div>
    </div>
  </header>
);
