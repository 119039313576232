import React from 'react';
import style from './index.module.css';
import { Link } from 'gatsby';

export default () => (
  <footer className={style.footer}>
    <nav className={style.footer__wrapper}>
      <ul className={style.footer__navList}>
        <li>
          <Link className={style.footer__link} to="/impressum">
            Impressum
          </Link>
        </li>
      </ul>
    </nav>
  </footer>
);
