import React from 'react';
import css from './index.module.css';
import { Link } from 'gatsby';

const pages = [
  {
    path: '/weine',
    name: 'Weine',
  },
  {
    path: '/rebberg',
    name: 'Rebberg',
  },
  {
    path: '/schoepfli',
    name: 'Schöpfli',
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
  },
];

const openModal = e => {
  e.preventDefault();
  e.target.nextElementSibling.style.display = 'block';
  document.body.style.overflow = 'hidden';
};

const closeModal = e => {
  e.preventDefault();
  e.target.parentElement.style.display = 'none';
  document.body.style.overflow = 'auto';
};

const navigationItems = pages.map((navItem, index) => (
  <li key={index} className={css.navigation__item}>
    <Link
      className={css.navigation__link}
      to={navItem.path}
      activeClassName={css.navigation__linkActive}
    >
      {navItem.name}
    </Link>
  </li>
));

export default () => (
  <nav>
    <a href="#navigation" onClick={openModal} className={css.navigation__open}>
      Entdecken
    </a>
    <div className={css.navigation__wrapper} id="navigation">
      <a href="/" onClick={closeModal} className={css.navigation__close}>
        Menu
      </a>
      <ul className={css.navigation__list}>{navigationItems}</ul>
    </div>
  </nav>
);
