import React from 'react';
import style from './index.module.css';
import Image from '../Image';

export default ({ children, image, title }) => (
  <article className={style.article}>
    {image && (
      <div className={style.article__image}>
        <Image image={image} />
      </div>
    )}

    <div className={style.article__content}>
      <h1>{title}</h1>
      {children}
    </div>
  </article>
);
